/* width */
::-webkit-scrollbar {
  width: 7px;
}

::-webkit-scrollbar:hover {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #555;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #144FC4;
}

@font-face {
  font-family: "dana VF";
  src: url("src/assets/fonts/dana2webGX.woff") format("woff-variations"),
    /* will be the standard and works in Safari now */
      url("src/assets/fonts/dana2webGX.woff") format("woff");
  /* for the other supporting browsers */
  /* font-display: fallback; */
}
/* dana version ? */

@font-face {
  font-family: "dana VF";
  src: url("src/assets/fonts/staticfonts/dana-regular.woff") format("woff");
}

@font-face {
  font-family: "dana VF";
  src: url("src/assets/fonts/staticfonts/dana-bold.woff") format("woff");
  font-weight: 700;
}
